@font-face {
	font-family: 'AkkoPro';
	src: url('../fonts/akkopro-regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/akkopro-regular.woff') format('woff'),
	url('../fonts/akkopro-regular.ttf')  format('truetype'),
	url('../fonts/akkopro-regular.svg#akkopro-regular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'AkkoProLight';
	src: url('../fonts/akkopro-light?#iefix') format('embedded-opentype'),
	url('../fonts/akkopro-light.woff') format('woff'),
	url('../fonts/akkopro-light.ttf')  format('truetype'),
	url('../fonts/akkopro-light.svg#akkopro-light') format('svg');
	font-weight: 200;
	font-style: normal;
}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,.hgroup,menu,nav,output,ruby,section,html .wrapper,html header,html footer,summary,time,mark,audio,video {
	margin:0;
	padding:0;
	border:0;
	font:inherit;
	font-size:100%;
	vertical-align:baseline;
}

html {
	line-height:1;
}

input, button, textarea {border-radius: 0;}

ol,ul {
	list-style:none;
}

table {
	border-collapse:collapse;
	border-spacing:0;
}

caption,th,td {
	text-align:left;
	font-weight:400;
	vertical-align:middle;
}

q,blockquote {
	quotes:none;
}

q:before,q:after,blockquote:before,blockquote:after {
	content:"";
	content:none;
}

a img {
	border:none;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: #ececec url("/sites/all/themes/zeitkraft16/img/images/select_bg.png");
	background-repeat: no-repeat;
	background-position: right;
	height: 30px;
	width: 100%;
/*	max-width: 350px;*/
	padding: 3px 10px;
	border: 0;
	border-radius: 0;
}

select:focus, textarea:focus, input:focus {
	outline: 0;
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,html wrapper,html header,html footer,summary {
	display:block;
}



html {
	color:#3C3C3B;
	font-size:14px;
}

html body {
	background: #f5f5f5;
	font-family: 'AkkoPro', 'PT Sans', sans-serif;
	line-height: 20px;
	font-size: 13.5px;
}

html a {
	color:#3C3C3B;
	display:block;

}

a#GAOptOut {
    display: inline-block;
}

html a:hover {
	color:#D72D2B;
	text-decoration:none;
}

html a:focus {
	outline: 0;
}

html a:hover .icon {
	fill:#fff;
}

html p {
	line-height:20px;
	text-transform:none;
	font-family: 'AkkoPro', 'PT Sans', sans-serif;
	margin: 0 0 10px;
}

html p.img-caption {
	font-size: 10px;
	margin: -10px 0 10px;
}

html h1 {
	font-size:32px;
	font-family: 'AkkoPro',sans-serif;
}

.front .headline h1 {
	max-width: 530px;
	line-height: 1.2;
	position: relative;
	z-index: 1;
}

.node-interner-job h2 {
	margin-top: 40px;
}

#block-block-50 {
	margin-top: 30px;
}

.zkpbewerben .single-box {
	height: 120px !important;
}

.zkpbewerben.right .single-box {
	float: right;
}

.node-interner-job .field-name-field-die-schwerpunkte-dieses-jo ul {
	margin-bottom: 20px;
}

.node-interner-job .field-name-field-die-schwerpunkte-dieses-jo p span {
	color: #333 !important;
}

html h2 {
	font-size:18px;
	padding-bottom:15px;
	position: relative;
	font-family: 'AkkoPro',sans-serif;
	text-transform: uppercase;
	letter-spacing: 0.2px;
}


#block-system-main h2:nth-child(n+2) {
	margin-top: 55px;
	padding-bottom: 5px;
}

#block-system-main h2:before,
.node-interner-job .field-name-field-die-schwerpunkte-dieses-jo .field-label:before {
	content: "";
	position: absolute;
	height: 4px;
	width: 70px;
	background: #000;
	top: -10px;
}

/*.node-interner-job .field-name-field-die-schwerpunkte-dieses-jo .field-label {*/
	/*padding: 0;*/
	/*position: inherit;*/
/*}*/


.responsive-start-page h2{
  font-family: "AkkoPro",arial,sans-serif;/*html header .logo img*/
  padding-top: 10px;
}

/* NAVIGATION */

.navbar-default {background: none;border: 0;border-radius: 0;}
.navbar-header img {width: 150px;height: auto !important; min-height: 1px;}
.navbar {margin-bottom: 15px;}
.navbar .region-headmenu {display: table;margin: 44px 0 0 auto;}
.navbar-default .navbar-nav>li>a {color: #000;}
.navbar-nav>li {margin-left: 5px;padding: 0;}
.nav>li>a {padding: 10px 10px;}
.navbar-toggle {margin-top: 26px;}
.navbar-toggle .icon-bar {width: 32px;}
.navbar-toggle .icon-bar+.icon-bar {margin-top: 6px;}
.navbar-default .navbar-toggle .icon-bar {background-color: #000;}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {background: none;}
.navbar-default .navbar-toggle {border: none;}
.navbar-default .navbar-nav>li>a:focus:before, .navbar-default .navbar-nav>li>a:hover:before, .navbar-default .navbar-nav>li>a.active-trail:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 4px;
	background: #000;
	top: 31px;
	left: 0;
}
.navbar-default .navbar-nav>li>a[href*="jobboerse"].focus:after, .navbar-default .navbar-nav>li>a[href*="jobboerse"].active:after, .navbar-default .navbar-nav>li>a[href*="jobboerse"]:hover:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 4px;
	background: #d72d2b;
	top: 31px;
	left: 0;
}
.navbar-default .navbar-nav>li>a[href*="jobboerse"] {
	background: #d72d2b;
	color: #fff;
}

a.navbar-btn {
	display: inline-block;
	margin-top: 16px;
}

/* END NAVIGATION */

html h3 {
	font-size:22px;
	padding-bottom:15px;
	font-family: 'AkkoPro',sans-serif;
}
html h3.subtitle {
	font-family: 'AkkoPro',sans-serif;
}
html h4 {
	font-family: 'AkkoPro',sans-serif;
	font-size:14px;
	font-weight: bold;
}

html .red {
	color:#D72D2B;
}

html .grey {
	color:#3C3C3B;
}

html .white {
	color:#fff;
}

ul.meilensteine li {
    list-style-type: none;
    margin-left: 0;
    padding-left: 70px;
}

ul.meilensteine .highlight {
    color: #D72D2B;
    width: 60px;
    display: inline-block;
    margin-left: -65px;
}

label {
	margin-bottom: 5px;
}

.unternehmen-page {padding-bottom: 50px;}

form input[type="text"], form input[type="email"] {width: 100%; height: 30px;border: 0;background: #ececec;padding: 5px 10px 4px;}

form input[type=file] {height: 35px;}
.webform-client-form #webform-component-html {
	margin-top: 0;
}

form #webform-component-upload .form-type-file{
	margin: 0;
}

form #edit-actions {text-align: right;}
#webform-ajax-wrapper-9212 #edit-actions {
	width: 100%;
	margin-top: 4px;
}

html .newsletter-form .form-group label,html .filter-form .form-group label {
    color: #fff;
    font-weight: normal;
    padding: 10px 0 0;
    text-transform: uppercase;
    width: 100%;
}

html .newsletter-form .form-group select,html .newsletter-form .form-group input,html .filter-form .form-group select,html .filter-form .form-group input,  #views-exposed-form-jobb-rse-block-1 select {
	width:100%;
	height:30px;
	text-transform:uppercase;
	line-height: 28px;
}

html .newsletter-form .form-group .select-arrow,html .filter-form .form-group .select-arrow {
	width:30px;
	height:30px;
	position:absolute;
	color:#fff;
	background:#3C3C3B;
	right:25px;
	top:initial;
	text-align:center;
	cursor:pointer;
}

html .newsletter-form .form-group .select-arrow:before,html .filter-form .form-group .select-arrow:before {
	top:10px;
	position:relative;
}

html .newsletter-form .form-group.email,html .filter-form .form-group.email {
	padding-left:0;
}

html .newsletter-form .form-group.plz,html .filter-form .form-group.plz {
	padding-right:0;
}

html .job-group-form {
	padding:20px 0;
}

html .job-group-form .form-group .job-group {
	padding:10px 0;
}

html .job-group-form .form-group .job-group input {
	visibility:hidden;
}

html .job-group-form .form-group .job-group label,
#edit-submitted-berufsgruppen label {
	cursor:pointer;
	position:absolute;
	width:30px;
	height:30px;
	top:0;
	background:url(../../img/icons/ZK_Icon_Checkbox.svg);
	background-position:-11px -11px;
	background-size:40px;
	padding-left:35px;
}

html .job-group-form .form-group .job-group input[type=checkbox]:checked+label, #edit-submitted-berufsgruppen input[type=checkbox]:checked+label {
	cursor:pointer;
	position:absolute;
	width:30px;
	height:30px;
	top:0;
	background:url(../../img/icons/ZK_Icon_CheckboxOn.svg);
	background-position:-11px -11px;
	background-size:40px;
	padding-left:35px;
}

html ul {
	line-height:35px;
}

html ul li {
	list-style-type:square;
	margin-left:18px;
	line-height: 20px;
}

.region-content ul li{
  text-transform: none;
	margin-bottom: 5px;
}

html .content-list {
	padding:0 0 15px;
}

html .content-list .list-type {
	width:40px;
	height:40px;
	background:#3C3C3B;
	color:#fff;
	float:left;
	margin:16px 10px 0 0;
}

html .content-list .list-type span {
	font-size:25px;
	position:absolute;
	left:13px;
	top:7px;
}

html .content-list p {
	display:table;
	padding-bottom:15px;
	padding-right: 10px !important;
}

html table tr td {
	padding:10px 10px 0 0;
}

html .red-btn,html .xing-btn,html .facebook-btn,html .google-btn {
	height:32px;
	min-width:142px;
	border:none;
	background:#D72D2B;
	color:#fff;
	letter-spacing:3px;
	position:relative;
}

html .xing-btn {
	background:#006567;
}

html .xing-btn a {
	color:#fff;
}

html .facebook-btn {
	background:#3B5998;
}

html .facebook-btn a {
	color:#fff;
}

html .google-btn {
	background:#dd4b39;
}

html .google-btn a {
	color:#fff;
}

html .icon {
	width:70px;
	height:70px;
	fill:#3C3C3B;
}

html .social-icon {
	width:20px;
	height:20px;
	position:absolute;
	left:0;
	margin:5px;
}

html header {
	margin:20px 0;
}

html header .logo img {
	width:100%;
	max-width: 124px;
	height:auto;
}

html header .print-contact {
	display:none;
}

html header .main-nav {
	padding:55px 0 0 20px;
}

html header .main-nav .navbar {
	margin-bottom:0;
}

.navbar {min-height: auto;}

/*
html header .main-nav .navbar .navbar-nav {
	float:right;
}
*/


html header .headarea {
	max-width: 930px;
	margin: 0 auto;
}

html header  .navbar-nav {font-family: 'AkkoProLight',sans-serif;float: right;}

html header .main-nav .navbar .navbar-nav li {
	padding:0 0px;
	margin-left:0!important;
}

html header .main-nav .navbar .navbar-nav li:hover a {
	background:#fff;
}
html header .main-nav .navbar .navbar-nav li a {
	font-size: 13px;
	position: relative;
	text-transform: uppercase;
	padding: 5px 10px 1px;
}

html header .main-nav .navbar .navbar-nav > li > a:focus {background: none;}
html header .main-nav .navbar .navbar-nav > li > a:hover, html header .main-nav .navbar .navbar-nav > li > a.active {color: #000;}

html header .main-nav .navbar .navbar-nav > li > a.active-trail:after, html header .main-nav .navbar .navbar-nav > li > a:hover:after {
	content: "";
	position: absolute;
	height: 4px;
	width: 100%;
	background: #000;
	left: 0;
	bottom: -7px;
}

html header .main-nav .navbar .navbar-nav > li > a[href*="jobboerse"].active:after, html header .main-nav .navbar .navbar-nav > li > a[href*="jobboerse"]:hover:after {
	content: "";
	position: absolute;
	height: 4px;
	width: 100%;
	background: #d72d2b;
	left: 0;
	bottom: -7px;
}
html header .main-nav .navbar .navbar-nav li a[href*="jobboerse"] {
	background: #d72d2b;
	color: #fff;
}
html header .main-nav .navbar .navbar-nav li:hover .dropdown-menu {
	display:block;
}

html header .main-nav .navbar .navbar-nav .dropdown:hover .dropdown-menu {
	display:block;
}

html header .main-nav .navbar .navbar-nav .dropdown .dropdown-menu {
	border:none;
	background:rgba(236,236,236,0.9);
}

html header .main-nav .navbar .navbar-nav .dropdown .dropdown-menu li {
	list-style-type:none;
}

html header .main-nav .navbar .navbar-nav .dropdown .dropdown-menu li a {
	background:rgba(236,236,236,0.1);
	padding:10px 20px;
	color: #000;
}

html header .main-nav .navbar .navbar-nav .dropdown .dropdown-menu li a:hover {
	color:#D72D2B;
}

html header .responsive-nav {
	margin: 20px 0 16px;
	min-height: 53px;
}

html header .responsive-nav .navbar .collapse {
	position:relative;
}

html header .responsive-nav .navbar-toggler {
	border:none;
	font-size:30px;
	color:#3C3C3B;
	background:transparent;
	outline: none;
	padding: 0;
	z-index: 999;
    margin: 18px 0 0;
	float: right;
}

html header .responsive-nav .navbar .navbar-toggler:focus{
  outline: none;
}

html header .responsive-nav .navbar .navbar-nav .dropdown-menu li {
	list-style-type:none;
}

html section, html .wrapper, html header{
	max-width:1000px;
	width: 100%;
	margin:0 auto;
	height:auto;
	background:#fff;
	position: relative;
}
 html footer {
	 background: #ececec none repeat scroll 0 0;
	 bottom: 0;
	 margin: 20px auto 0;
	 position: fixed;
	 width: 100%;
   max-width: 100%;
}

.navbar-header > a.navbar-btn {
	width: 150px;
}

#block-system-main a:focus {
	text-decoration: none;
}

a[title~=Zurück] {
	float: right;
}

#block-system-main a.button,
input[type=submit],
.job-results .result-btn a {
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	text-transform: uppercase;
	letter-spacing: 0.2px;}
#block-system-main a.button:hover,
input[type=submit]:hover,
.job-results .result-btn a:hover {background: #ff4a4f;}

/*#block-system-main a:not([class*="contextual"]), */
#block-system-main a.button {
	background: #d72d2b;
	color: #fff;
	padding: 6px 20px 4px;
	display: inline-block;
	margin-top: 15px;
	font-size: 11px;
	text-transform: uppercase;
}

#block-system-main .node-logoblock a {
	background: none;
	padding: 0;
	margin: 0;
}


#edit-submitted-upload-upload .form-submit {
	margin-top: 20px;
}

#webform-component-upload {
	margin-bottom: 0;
}

.webform-confirmation {
	padding-left: 40px;
	position: relative;
}

.block-webform {margin-bottom: 20px;}

.block-webform .links {padding-left: 40px;}

.webform-confirmation:before {
	content: "\f00c";
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 25px;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	left: 10px;
	top: -10px;
	color: #7acc40;
}

.view-jobb-rse table tr td:first-child {
	width: 70px;
}

.job-results .result-btn a:focus {
	text-decoration: none;
}

.job-results .result-btn a {
	background: #d72d2b;
	color: #fff;
	padding: 6px 20px 4px;
	display: inline-block;
	margin-top: 11px;
	font-size: 11px;
	text-transform: uppercase;
}

.job-results td.description a {
	font-family: "PT Sans",sans-serif;
	line-height: 14px;
	text-transform: uppercase;
	padding: 3px 0;
}

.view-standorte .standorte {
	margin-bottom: 70px;
}

.view-standorte .standorte .views-field-name {
	text-transform: uppercase;
}

#block-system-main .view-standorte .views-row a[href*="mail"] {
	background: none;
	color: initial;
	padding: 0;
	margin: 0;
	color: #D72D2B !important;
	text-transform: none;
}

#block-system-main .view-standorte .views-row a {background: none;color: #000;padding: 0;margin-top: 0;font-size: 13.5px;display: inline-block;}
#block-system-main .view-standorte .views-row a[href*="mailto"] {margin-top: 0;}
.block-webform .messages.error {
	background: none;
	padding: 10px;
	color: #D72D2B;
}


.region-header-overlay .views-exposed-form .views-exposed-widgets > .views-exposed-widget.views-submit-button {
	padding-right: 0;
	width: 20% !important;
}

.region-header-overlay .views-exposed-form .views-exposed-widgets > .views-exposed-widget {
	padding-right: 2%;
	width: 73% !important;
	font-family: 'AkkoProLight', serif;
	font-size: 11px;
}


div[id*=-ajax] > div {
	margin-bottom: 30px;
}

.form-item input.error, .form-item textarea.error, .form-item select.error {
	border: 1px solid #D72D2B;
}

.region-searchblock {
	position: absolute;
	z-index: 10;
	width: 410px;
	left:70px;
	bottom:70px;
  h2 {
    color: white;
  }
}

.view-standorte .view-grouping .view-grouping-header,
.node-standort  .mybody {
	clear: both;
	display: block;
	float: none;
	font-size: 24px;
	margin-bottom: 30px;
	padding-left: 15px;
}

.view-standorte .view-grouping {
	clear: both;
}

#crusoe-searchblock-form .form-item.form-type-textfield.form-item-jobtitel {
	float: left;
	margin-right: 16px;
	margin-top: 0;
	width: 290px;
}

/*#crusoe-searchblock-form .buttonbandarole {*/
	/*margin-top: 1em;*/
/*}*/

#crusoe-searchblock-form input[type="submit"] {
	/*background: #333333 none repeat scroll 0 0;*/
	font-size: 12px;
	height: 30px;
	letter-spacing: 1px;
	line-height: 30px;
	padding-bottom: 1px;
	padding-top: 1px;
}

#crusoe-searchblock-form .form-btn.links {
	letter-spacing: 1px;
	text-transform: uppercase;
}

.region-searchblock .form-btn {
	display: inline;
}

.region-searchblock .form-btn a:hover, .region-searchblock .form-btn a:focus {
	color: #000;
	text-decoration: underline;
}

.region-searchblock .form-btn a {
	display: inline-block;
	padding: 5px 10px;
	margin-left: -10px;
	margin-top: 5px;
}

#crusoe-searchblock-form label {
	font-size: 25px;
	text-transform: uppercase;
}

body.page-node-1268 .inner-content > h3,
body.page-node-57 .inner-content > h3 {
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    margin: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
}

body.page-node-1268 .inner-content #block-system-main,
body.page-node-57 .inner-content #block-system-main,
body.page-node-57 .inner-content #block-views-jobb-rse-block-1 .view-empty {
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}

html section .content-row .content-box .inner-content,html wrapper .content-row .content-box .inner-content,html header .content-row .content-box .inner-content,html footer .content-row .content-box .inner-content {
	padding:30px;
}

html section .content-row .single-box,html wrapper .content-row .single-box,html header .content-row .single-box,html footer .content-row .single-box,html section .content-row .double-box,html wrapper .content-row .double-box,html header .content-row .double-box,html footer .content-row .double-box,html section .content-row .trible-box,html wrapper .content-row .trible-box,html header .content-row .trible-box,html footer .content-row .trible-box {
	height:200px;
	border:3px solid #fff;
	text-align:center;
}

html section .content-row .single-box .inner-content,html wrapper .content-row .single-box .inner-content,html header .content-row .single-box .inner-content,html footer .content-row .single-box .inner-content,html section .content-row .double-box .inner-content,html wrapper .content-row .double-box .inner-content,html header .content-row .double-box .inner-content,html footer .content-row .double-box .inner-content,html section .content-row .trible-box .inner-content,html wrapper .content-row .trible-box .inner-content,html header .content-row .trible-box .inner-content,html footer .content-row .trible-box .inner-content {
	display:block;
}

html .job-group-form .form-group,html .job-group-form .form-group .job-group,html header .main-nav,html section,html wrapper,html header,html footer,html section .content-row .content-box .inner-content,html wrapper .content-row .content-box .inner-content,html header .content-row .content-box .inner-content,html footer .content-row .content-box .inner-content,.main-slider,.prof-groups,.prof-groups .group-item a,body.page-node-57 .job-results {
	*zoom:1;
}

html .job-group-form .form-group::before,html .job-group-form .form-group .job-group::before,html header .main-nav::before,html section::before,html wrapper::before,html header::before,html footer::before,html section .content-row .content-box .inner-content::before,html wrapper .content-row .content-box .inner-content::before,html header .content-row .content-box .inner-content::before,html footer .content-row .content-box .inner-content::before,.main-slider::before,.prof-groups::before,.prof-groups .group-item a::before,body.page-node-57 .job-results::before,html .job-group-form .form-group::after,html .job-group-form .form-group .job-group::after,html header .main-nav::after,html section::after,html wrapper::after,html header::after,html footer::after,html section .content-row .content-box .inner-content::after,html wrapper .content-row .content-box .inner-content::after,html header .content-row .content-box .inner-content::after,html footer .content-row .content-box .inner-content::after,.main-slider::after,.prof-groups::after,.prof-groups .group-item a::after,body.page-node-57 .job-results::after {
	content:" ";
	display:table;
}

html .job-group-form .form-group::after,html .job-group-form .form-group .job-group::after,html header .main-nav::after,html section::after,html wrapper::after,html header::after,html footer::after,html section .content-row .content-box .inner-content::after,html wrapper .content-row .content-box .inner-content::after,html header .content-row .content-box .inner-content::after,html footer .content-row .content-box .inner-content::after,.main-slider::after,.prof-groups::after,.prof-groups .group-item a::after,body.page-node-57 .job-results::after {
	clear:both;
}

html section .content-row .single-box .inner-content,html wrapper .content-row .single-box .inner-content,html header .content-row .single-box .inner-content,html footer .content-row .single-box .inner-content,html section .content-row .double-box .inner-content,html wrapper .content-row .double-box .inner-content,html header .content-row .double-box .inner-content,html footer .content-row .double-box .inner-content,html section .content-row .trible-box .inner-content,html wrapper .content-row .trible-box .inner-content,html header .content-row .trible-box .inner-content,html footer .content-row .trible-box .inner-content,.ticker-header h4,.prof-groups .group-item .item {
	position:relative;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
}

.bold,html .red-btn:hover,html .xing-btn:hover,html .facebook-btn:hover,html .google-btn:hover,.ticker-content ul li:before,body.page-node-57 .content-row .inner-content .filter-results ul li .remove,body.page-node-57 .job-results .result-row tr td.description,
.view-interne-stellenangebote .result-row tr td.description {
	font-weight:700;
}



#edit-field-geofield-distance-wrapper .form-item-field-geofield-distance-origin {
	width: 70%;
	margin: 0;
	margin-left: 23px;
}

.left-border-control {
	border-left:0!important;
}

.right-border-control {
	border-right:0!important;
}

.red-box {
	background:#D72D2B;
	color:#fff;
}

.red-box.hover:hover {
	background:#3C3C3B;
}

.red-box.hover:hover a,.red-box.hover:hover .icon {
	color:#fff;
	fill:#fff;
}

.red-box a,.red-box .icon {
	color:#fff;
	fill:#fff;
}

#block-menu-menu-footer-menu li.dropdown:nth-child(1) a:after {
	content: "";
	background: transparent url(/sites/all/themes/zeitkraft16/img/icons/bewerben_grau.png);
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	height: 28px;
	background-repeat: no-repeat;
	background-size: 15px;
	background-position: left 50%;
}

#block-menu-menu-footer-menu li.dropdown:nth-child(2) a:after {
	content: "";
	background: transparent url(/sites/all/themes/zeitkraft16/img/icons/rueckruf_grau.png);
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	height: 28px;
	background-repeat: no-repeat;
	background-size: 15px;
	background-position: left 50%;
}

#block-menu-menu-footer-menu li.dropdown:nth-child(3) a:after {
	content: "";
	background: transparent url(/sites/all/themes/zeitkraft16/img/icons/standort_grau.png);
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	height: 28px;
	background-repeat: no-repeat;
	background-size: 15px;
	background-position: left 50%;
}

#block-menu-menu-footer-menu li.dropdown:nth-child(4) a:after {
	content: "";
	background: transparent url(/sites/all/themes/zeitkraft16/img/icons/newsletter_grau.png);
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	height: 28px;
	background-repeat: no-repeat;
	background-size: 15px;
	background-position: left 50%;
}

.logo {
	margin: 20px 0 16px;
}

.dark-grey-box {
	background:#3C3C3B;
	color:#fff;
}

.dark-grey-box.hover:hover {
	background:#ECECEC;
}

.dark-grey-box.hover:hover a,.dark-grey-box.hover:hover .icon {
	color:#3C3C3B;
	fill:#3C3C3B;
}

.dark-grey-box a,.dark-grey-box .icon {
	color:#fff;
	fill:#fff;
}

.light-grey-box {
	background:#ECECEC;
	color:#3C3C3B;
}

.light-grey-box.hover:hover {
	background:#D72D2B;
}

.light-grey-box.hover:hover a,.light-grey-box.hover:hover .icon {
	color:#fff;
	fill:#fff;
}

.light-grey-box a,.light-grey-box .icon {
	color:#3C3C3B;
	fill:#3C3C3B;
}

select::-ms-expand {
	display: none;
}

.intro-page {
	padding-bottom:70px;
}

.intro-page .intro-header {
	background:#fff;
	text-align:center;
	margin:25px 0;
}

.intro-page wrapper.intro-page {
	padding-bottom:70px;
}

.intro-page .intro-slider {
	background-size:cover;
	cursor: pointer;
}

.intro-page .intro-slider .slider-content {
	background:rgba(255,255,255,0.8);
	width:600px;
	height:250px;
	position:absolute;
	left:0;
	bottom:10%;
}

.intro-page .intro-slider .slider-content .inner-content {
	padding:8% 5%;
}

.intro-page .intro-slider img {
	width:100%;
	height:auto;
}

.intro-page .intro-footer .intro-logos {
	text-align:center;
	padding:40px 25px;
}

.intro-page .intro-footer .intro-logos img {
	width:50%;
}

.intro-page .intro-footer .next-arrow {
	text-align:center;
	font-size:20px;
	cursor:pointer;
	padding-bottom:15px;
}

.intro-page .intro-footer .next-arrow span {
	font-size:30px;
	color:#D72D2B;
}

.field-name-field-logo2 img {
	width: 90%;
	max-width: 100%;
}

::-webkit-input-placeholder {color: #333 !important;}
:-moz-placeholder {color: #333;opacity:  1;}
::-moz-placeholder {color: #333;opacity:  1;}
:-ms-input-placeholder {color: #333;}


.hastitlebox {
	position: relative;
	margin: 0 auto 35px;
	max-width: 930px;
	overflow: hidden;
}

.notitlebox, .hastitlebox {
	position: relative;
	margin: 0 auto 35px;
	max-width: 930px;
	overflow: hidden;
}

.main-slider {
	background-size:cover!important;
	padding-top: 0;
	position: relative;
	height: 345px;
	background-position: 50% 50% !important;
	background-repeat: no-repeat;
	overflow: hidden;
	margin: 0 auto;
  .frontpage-zklogo {
    position: absolute;
    right: 40px;
    bottom: 90px;
  }
}

.braun .title-box {
	background: #ba774b;
}
.blau .title-box {
	background: #22588f;
}

.gelb .title-box {
	background: #f0b93a;
}

.violett .title-box {
	background: #605686;
}

.title-box {
	position: absolute;
	left: 0;
	bottom: 0;
	width: calc(100% + 20px);
	max-width: 335px;
	height: 240px;
	display: table;
	background: #ba774b;
}

.title-box h2 {
	font-family: 'AkkoProLight', serif;
	padding: 15px 25px 55px;
	vertical-align: bottom;
	display: table-cell;
	color: #fff;
	font-size: 28px;
	line-height: 1.2;
}

.field-name-field-spalte-1 img {
	width: 100%;
	height: auto;
}

fieldset#webform-component-daten h2 {
	color: #ffffff;
	font-family: "AkkoProLight",serif;
	font-size: 30px;
	line-height: 1.2;
	vertical-align: bottom;
}

fieldset#webform-component-daten h2:before {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	content: none;
	height: 0;
	position: inherit;
	top: 0;
	width: 0;
}

#webform-component-daten .form-item {
	margin-bottom: 10px !important;
}

.main-slider img{
}

.main-slider .slider-content {
/*	background:rgba(0,0,0,0.7);*/
	float:right;
	height:100%;
	padding:25px;
}

.title-box .region-header-overlay h2 {
	padding: 0;
	margin: -10px 0 10px;
	display: inline-block;
}

#views-exposed-form-jobb-rse-block-1 input[type=text], #views-exposed-form-jobb-rse-block-1 select {
	font-size: 13px;
	text-transform: uppercase;
}

html .title-box .newsletter-form .form-group label,
html .title-box .filter-form .form-group label,
.title-box #views-exposed-form-jobb-rse-block-1 label {
	padding: 0;
	margin-bottom: 0;
}

.title-box #views-exposed-form-jobb-rse-block-1 label {color: #fff;}

#edit-submit-jobb-rse {margin-top: 1.9em;}

.info-white {
	color:#fff;
}

.info-link {
	color:#fff;
	margin-top:30px;
}

.info-link:hover {
	color:#fff;
	text-decoration:underline;
}

.ticker-header {
	width:100%;
	height:50px;
	background:#ECECEC;
}

.ticker-content {
	width:100%;
	height:150px;
	background:#fff;
	overflow:auto;
}

.ticker-content ul {
	text-align:left;
	padding:10px 25px;
}

.ticker-content ul li {
	line-height:25px;
	list-style:none;
}

.ticker-content ul li:before {
	content:"-";
	padding-right:8px;
	float:left;
}

.kunden-page .content-row .main-slider {
	border:3px solid #fff;
}

.region-right-col-doublerow .main-slider .slider-content,
.kunden-page .content-row .main-slider .slider-content {
	background:rgba(255,255,255,0.8);
	width:100%;
	height:250px;
	position:absolute;
	left:0;
	bottom:10%;
}

.prof-groups {
	padding:30px 0;
}

.prof-groups .group-item {
	height:70px;
	padding:0;
}

.prof-groups .group-item:hover {
	background:#3C3C3B;
	fill:#fff;
}

.prof-groups .group-item:hover .item {
	color:#fff;
}

.prof-groups .group-item a {
	height:70px;
}

.prof-groups .group-item .icon {
	float:left;
}

.prof-groups .group-item .item {
	font-family:"AkkoPro",arial,sans-serif;
	font-size:15px;
}

.bewerber-page .main-slider {
	border-top:3px solid #fff;
	border-right:3px solid #fff;
}

.bewerber-page .inner-content h4 {
	padding-bottom:20px;
}

.bewerber-page .evaluate-list {
	line-height:35px;
}

.bewerber-page .evaluate-list li {
	list-style-type:square;
	margin-left:18px;
}

body.page-node-57 .content-row .inner-content .job-header .job-intro .result-count {
	color:#D72D2B;
}

body.page-node-57 .content-row .inner-content .filter-results h4 {
	padding-bottom:15px;
}

body.page-node-57 .content-row .inner-content .filter-results ul {
	line-height:25px;
}

body.page-node-57 .content-row .inner-content .filter-results ul li {
	list-style-type:none;
}

body.page-node-57 .content-row .inner-content .filter-results ul li .remove {
	float:left;
	padding-right:5px;
}

body.page-node-57 .job-results,
.view-interne-stellenangebote .job-results {
	border:3px solid #fff;
	padding:5px 15px 5px;
}

body.page-node-57 .job-results:nth-child(odd),
.view-interne-stellenangebote .job-results:nth-child(odd) {
  background-color: white;
}

body.page-node-57 .job-results:nth-child(even),
.view-interne-stellenangebote .job-results:nth-child(even) {
  background-color: #ECECEC;
}

body.page-node-57 .job-results .result-row .result-btn button,
.view-interne-stellenangebote .result-row .result-btn button {
	margin:20px 0;
	min-width:100px;
}

body.page-node-57 .job-results .result-row .result-btn button a,
.view-interne-stellenangebote .result-row .result-btn button a {
	color:#fff;
}

.jobdetail-page .job-detail .inner-content h4 {
	padding:25px 0 10px;
}

.jobdetail-page .job-infos .result-btn {
	padding:20px 0 0;
}

.jobdetail-page .job-infos .result-btn button {
	margin:0 6px 6px 0;
	text-align:right;
}

.jobdetail-page .job-infos .result-btn button.red-btn,.jobdetail-page .job-infos .result-btn html button.xing-btn,html .jobdetail-page .job-infos .result-btn button.xing-btn,.jobdetail-page .job-infos .result-btn html button.facebook-btn,html .jobdetail-page .job-infos .result-btn button.facebook-btn,.jobdetail-page .job-infos .result-btn html button.google-btn,html .jobdetail-page .job-infos .result-btn button.google-btn {
	line-height:30px;
}

.jobdetail-page .job-infos .result-btn button.red-btn span,.jobdetail-page .job-infos .result-btn html button.xing-btn span,html .jobdetail-page .job-infos .result-btn button.xing-btn span,.jobdetail-page .job-infos .result-btn html button.facebook-btn span,html .jobdetail-page .job-infos .result-btn button.facebook-btn span,.jobdetail-page .job-infos .result-btn html button.google-btn span,html .jobdetail-page .job-infos .result-btn button.google-btn span {
	position:absolute;
	font-size:20px;
	left:0;
	margin:5px 8px;
}

.jobdetail-page .job-infos .result-btn button.xing-btn {
	height:33px;
	top:-1px;
}

.jobdetail-page .job-infos .result-btn button.facebook-btn {
	margin-bottom:0;
}

.jobdetail-page .job-infos .result-btn button.facebook-btn .social-icon {
	margin:5px 3px;
}

.jobdetail-page .job-infos .result-btn button.google-btn {
	margin-bottom:0;
}

.jobdetail-page .job-infos .result-btn button.google-btn .social-icon {
	height:30px;
	width:30px;
	margin:0 5px;
}

footer .footer {
	max-width: 1000px;
	margin: 0 auto;
	float: none;
	text-transform: uppercase;
}

footer .impressum {
	font-size: 10px;
	display: inline-block;
	float: right;
}


footer .impressum a {
	display: inline-block;
}

footer .footer #copyright,footer .footer #impressum,footer .footer span {
	padding:4px 5px;
}

html footer ul li {margin-left: 0;}

.row-wrapper {
	max-width: 930px;
	margin: 0 auto;
}

.braun .title-box:before {
	background: #ba774b;
}
.blau .title-box:before {
	background: #22588f;
}

.gelb .title-box:before {
	background: #f0b93a;
}

.violett .title-box:before {
	background: #605686;
}

.title-box:before {
	content: "";
	top: -65px;
	background: #ba774b;
	position: absolute;
	right: 7px;
	width: 420px;
	transform: rotate(17deg);
	height: 109px;
}

.start-page .main-slider {
	height:745px;
	border-bottom:0;
	position: relative;
	overflow: hidden;
	background-position: left center !important;
	padding: 0;
}

.front .headline {
	position: absolute;
	bottom: 0;
	background: #42253a;
	width: 100%;
	height: 150px;
	padding: 35px 120px 40px 35px;
	color: #fff;
	text-transform: uppercase;
	font-size: 30px;
}

.front .headline:before {
	content: "";
	top: -25px;
	background: #42253a;
	position: absolute;
	left: -5px;
	width: 100%;
	height: 70px;
	transform: rotate(3deg);
}

.front .headline .corner {
	background: #fff;
	height: 300px;
	width: 300px;
	position: absolute;
	right: -187px;
	bottom: -113px;
	transform: rotate(35deg);
}

.start-page {
	overflow: hidden;
}

.front .headline {
	height: 100px;
	position: relative;
	background: #42253a;
}

#block-system-main .views-field-field-e-mailadresse a {font-size: 13px;}

footer .navbar-nav>li {margin-left: 0px;}
footer .navbar-nav>li:before {content:"";position: absolute;height: 100%;width: 4px;background: #fff;}
footer .navbar-nav>li>a {padding: 4px 45px 5px 15px;text-transform: uppercase;font-size: 12px;}
footer .navbar-nav>li>a:hover, footer .navbar-nav>li>a:focus {background: none;}
footer a:hover, footer a:focus {color: #000;text-decoration: underline !important;}


@media screen and (max-width: 1200px) {

	.content-row {
		padding: 0 25px;
	}
}

@media screen and (min-width: 1025px) {
	.responsive-nav {
		display:none;
	}


	.responsive-start-page {
		display:none;
	}
}

@media screen and (max-width: 1024px) {
	.responsive-nav {
		display:none;
	}

	.responsive-start-page {
		display:block;
	}

	.intro-page .intro-footer .next-arrow {
		margin-top:60px;
	}

	.prof-groups .group-item .item {
		font-size:13px;
	}
}

@media screen and (max-width: 989px) {
	.left-border-control {
		border-left:3px solid #fff!important;
	}

	.right-border-control {
		border-right:3px solid #fff!important;
	}

	body.page-node-57 .inner-content .job-header .filter-results {
		padding:20px 0 0;
	}

	body.page-node-57 .job-results {
		padding:0;
	}

/*
	body.page-node-57 .job-results:nth-child(odd) {
		background-color:transparent;
	}
*/

	body.page-node-57 .job-results .result-row:nth-child(even) {
		background-color:#ECECEC;
	}

	body.page-node-57 .job-results .result-row .result-btn {
		padding:5px 0;
	}
}

@media screen and (min-width: 769px) {
	.navbar-nav>li>a {
		padding-top: 5px;
		padding-bottom: 3px;
	}

	.navbar-toggle {display: none;}

	.navbar-collapse {
		width: auto;
		border-top: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.navbar-collapse.collapse {display: block !important;}

}

@media screen and (min-width: 768px) {


	/*.navbar-nav>li>a {*/
		/*padding: 5px;*/
	/*}*/

	.group-spalte-1 {padding-right: 10px;}

	.field-name-field-spalte-1 {padding-right: 10px;}

	.field-name-field-spalte-2 {padding: 0 10px;}

	.field-name-field-spalte-3, .field-name-field-logo2 {padding-left: 10px;}

	.group-spalte-2 .field.field-name-body {padding: 0 10px;}

	.group-spalte-2 h2 {width: 50%;padding: 0 10px 15px;}

}

@media screen and (max-width: 768px) {

	.navbar-default .navbar-nav>li>a[href*="jobboerse"] {
		background: transparent;
		color: #d72d2b;
	}

	#block-system-main-menu a.active-trail.active, #block-system-main-menu a:hover {
		background: #efefef;
	}

	.navbar-default .navbar-nav>li>a[href*="jobboerse"].focus:after, .navbar-default .navbar-nav>li>a[href*="jobboerse"].active:after, .navbar-default .navbar-nav>li>a[href*="jobboerse"]:hover:after {
		display: none;}


	html footer {margin: 0 auto;}
	.main-slider .slider-content {margin-bottom: 0;}

	.navbar-nav > li {
		display: block;
		margin-left: 0;
		float: none;
	}

	.navbar-header {float: none;}


	.navbar-default .navbar-collapse, .navbar-default .navbar-form {border: 0;}

	.navbar .region-headmenu {display: block;text-align: center;margin: 20px 0 0 auto;}

	.navbar-default .navbar-nav>li>a:focus:before, .navbar-default .navbar-nav>li>a:hover:before, .navbar-default .navbar-nav>li>a.active-trail:before {
		display: none;
	}

	.container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
		margin-right: -15px;
		margin-left: -15px;
	}

	.navbar-collapse.collapse.in {display: block !important;}

	.navbar-toggle {display: block;}

	a.navbar-btn {padding-left: 20px;}

	footer .navbar-nav>li>a {padding: 10px 15px 10px 15px;}

	footer {position: relative !important;}

	body.front footer {
		position: relative;
	}

	.field-name-field-spalte-3 {margin-top: 55px;padding-bottom: 5px;}
	footer .footer {text-align: center; padding: 10px 0;}
	footer .impressum {margin: 10px 0 10px;float:none;text-align: center;}
	footer .impressum a {display: inline;}
	footer .navbar-nav>li>a:after {content:"";background: none !important;}
	footer .navbar-nav>li:before {display: none;}

	.start-page .main-slider {height:100vw;}
	.front .headline {height: 50px;}

	.main-nav {
		display:none;
	}

	.responsive-nav {
		display:block;
	}

	.bewerber-page .main-slider {
		border-top:0;
		border-right:0;
		border-bottom:3px solid #fff;
	}
}

@media print {
	.main-nav {
		padding:0;
	}

	.main-nav .logo {
		display:none;
	}

	.main-nav .print-contact {
		display:block!important;
	}

	.main-nav .print-contact img {
		padding:0 20pt 0 0;
		width:70pt;
		float:left;
	}

	.main-nav .print-contact p {
		font-family:'PT Sans',Helvetica,sans-serif;
		font-size:5pt;
		line-height:8pt;
		letter-spacing:.5pt;
		padding:0;
	}

	.main-nav .navbar {
		display:none;
	}

	.jobdetail-page .main-slider {
		display:none;
	}

	.jobdetail-page .content-row .job-detail .inner-content {
		padding:0;
	}

	.jobdetail-page .content-row .job-detail .inner-content h3 {
		font-size:9pt;
		padding:0;
	}

	.jobdetail-page .content-row .job-detail .inner-content h4 {
		font-size:7pt;
		padding:5pt 0 0;
	}

	.jobdetail-page .content-row .job-detail .inner-content p {
		font-family:'PT Sans',Helvetica,sans-serif;
		font-size:5pt;
		line-height:8pt;
		letter-spacing:.5pt;
		padding:0;
	}

	.jobdetail-page .content-row .job-detail .inner-content ul {
		line-height:10pt;
	}

	.jobdetail-page .content-row .job-detail .inner-content ul li {
		font-size:5pt;
		padding:0;
	}

	.jobdetail-page .job-infos .inner-content {
		padding:0!important;
	}

	.jobdetail-page .job-infos .inner-content table tr td {
		font-size:5pt;
	}

	.jobdetail-page .job-infos .result-btn {
		display:none;
	}

	.jobdetail-page .single-box,.jobdetail-page html section .content-row .double-box,html section .content-row .jobdetail-page .double-box,.jobdetail-page html wrapper .content-row .double-box,html wrapper .content-row .jobdetail-page .double-box,.jobdetail-page html header .content-row .double-box,html header .content-row .jobdetail-page .double-box,.jobdetail-page html footer .content-row .double-box,html footer .content-row .jobdetail-page .double-box,.jobdetail-page html section .content-row .trible-box,html section .content-row .jobdetail-page .trible-box,.jobdetail-page html wrapper .content-row .trible-box,html wrapper .content-row .jobdetail-page .trible-box,.jobdetail-page html header .content-row .trible-box,html header .content-row .jobdetail-page .trible-box,.jobdetail-page html footer .content-row .trible-box,html footer .content-row .jobdetail-page .trible-box {
		display:none;
	}

	footer .footer {
		display:none;
	}
}



/* KONTAKTFORMULAR */

form#webform-client-form-55, form#webform-client-form-1360 {
    max-width: 500px;
    margin: 0 auto;
	position: relative;
	text-transform: none;
}

form#webform-client-form-55 .form-item, .form-actions,
form#webform-client-form-1360 .form-item, .form-actions {
	width: 100%;
	display: inline-block;
	margin: 1em 0 0;
}

form#webform-client-form-55 div > fieldset,
form#webform-client-form-1360 div > fieldset {
	margin-bottom: 1em;
	clear: both;
	position: relative;
}

/*
form#webform-client-form-55 div > fieldset legend {
    margin-bottom: 10px;
}
*/

form#webform-client-form-55 input,
form#webform-client-form-1360 input {
	margin-top: 0;
}

form#webform-client-form-55 input[type=checkbox], form#webform-client-form-55 input[type=radio],
form#webform-client-form-1360 input[type=checkbox], form#webform-client-form-1360 input[type=radio] {

}

form#webform-client-form-55 input:not([type=checkbox]):not([type=radio]):not([type=file]),
form#webform-client-form-1360 input:not([type=checkbox]):not([type=radio]):not([type=file]) {
	height: 30px;
	width: 100%;
	border: 0;
	padding: 5px;
}

input[type=file] {
	width: 100%;
	height: 30px;
}

input[type=submit] {
	color: #fff;
	background: #d72d2b;
	border: 0;
	padding: 6px 20px 4px;
	text-transform: uppercase;
	font-size: 11px;
	font-family: 'AkkoProLight', serif;
}
/*

form#webform-client-form-55 input[type="button"], form#webform-client-form-55 input[type="button" i] {
	-webkit-appearance: button;
	height: 30px;
	background: #fff;
	color: #fff;
}
*/

form#webform-client-form-55 legend span,
form#webform-client-form-1360 legend span {
	font-size: 15px;
	font-family: 'PT Sans', arial, sans-serif;
	text-transform: uppercase;
}

form#webform-client-form-55 label,
form#webform-client-form-1360 label {
	width: 100%;
	padding-bottom: 5px;
	vertical-align: middle;
}

form#webform-client-form-55 #webform-component-ihre-daten--strasse {
	padding-right: 10px;
	width: 75%;
	float: left;
}
form#webform-client-form-55 #webform-component-ihre-daten--hausnr {
	width: 25%;
	float: left;
}

form#webform-client-form-55 #webform-component-ihre-daten--plz {
	float: left;
	width: 25%;
	padding-right: 10px;
}

form#webform-client-form-55 #webform-component-ihre-daten--stadt {
	float: left;
	width: 75%;
}

form#webform-client-form-55 #webform-component-ihre-fremdsprachen label {
	display: inline-block;
	margin: 0;
}

form#webform-client-form-55 #webform-component-ihre-fremdsprachen .fieldset-wrapper > div {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}

form#webform-client-form-55 #webform-component-ihre-fremdsprachen .fieldset-wrapper > div > div > div {
	float: left;
	width: 33.3333%;
	margin-top: 0;
}

form#webform-client-form-55 #webform-component-ihre-fremdsprachen .fieldset-wrapper > div > div > div input {
	width: initial;
	margin: 0;
}

form#webform-client-form-55 #webform-component-ihre-fremdsprachen .fieldset-wrapper > div > div > div label {
    display: inline;
    margin-right: 0;
	vertical-align: middle;
}

form#webform-client-form-55 #webform-component-ihre-fremdsprachen--sonstige {
	margin-top: 5px;
}

form#webform-client-form-55 #webform-component-ihre-pc-kenntnisse .fieldset-wrapper > div {
    margin: 0;
    display: inline-block;
}

form#webform-client-form-55 #webform-component-ihre-pc-kenntnisse .fieldset-wrapper > div > div > div {
    float: left;
    width: 33.3333%;
}

form#webform-client-form-55 #webform-component-fuhrerschein-vorhanden, form#webform-client-form-55 #webform-component-eigener-pkw-vorhanden {
	width: 50%;
	float: left;
	margin-bottom: 17px;
	margin-top: 0;
}

form#webform-client-form-55 #webform-component-kunde input[type=submit] {
	margin-bottom: 10px;
}

form#webform-client-form-55 #edit-submitted-kunde-lebenslauf-ajax-wrapper, form#webform-client-form-55 #edit-submitted-kunde-zeugnisse-ajax-wrapper{
	margin-bottom: 5px
}

form#webform-client-form-55 .form-textarea-wrapper textarea, form#webform-client-form-1360 .form-textarea-wrapper textarea {
	border: 0;
	border-radius: 0;
	background: #ececec none repeat scroll 0 0;
	padding: 5px;
	resize: none;
}


form#webform-client-form-55 .form-textarea-wrapper .grippie,
form#webform-client-form-1360 .form-textarea-wrapper .grippie {
	display: none;
}

form#webform-client-form-55 #edit-submitted-kunde-ich-bitte-um-ein-unverbindliches-angebot-fur-ihre-dienstleistung > div {
    width: 33.3333%;
    float: left;
}

/*
form#webform-client-form-55 .form-textarea-wrapper {
    margin: 0.5em 0;
}
*/

.ie9 img[src$=".svg"] {
	width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	img[src$=".svg"] {
		width: 100%;
	}
}

form#webform-client-form-55 #webform-component-kunde--wir-bitten-um-kontaktaufnahme > label,
form#webform-client-form-55 #webform-component-kunde--senden-sie-uns-ihr-angebot > label,
form#webform-client-form-55 #webform-component-kunde--wir-mochten-sie-kennen-lernen > label {
	display: inline-block;
}

form#webform-client-form-55 #webform-component-kunde--anzahl-der-benotigten-mitarbeiter label,
form#webform-client-form-55 #webform-component-kunde--gewunschter-einsatz-von-bis label {
	display: inline-block;
}

form#webform-client-form-55 #edit-submitted-kunde-wir-bitten-um-kontaktaufnahme > div,
form#webform-client-form-55 #edit-submitted-kunde-senden-sie-uns-ihr-angebot > div {
    width: 33.3333%;
    float: left;
}

#webform-client-form-1360 #webform-component-erreichbar-zwischen label, form#webform-client-form-1360 #webform-component-und label {
	margin-top: 8px;
	margin-right: 10px;
	float: left;
	display: inline;
	width: auto !important;
}

#webform-client-form-1360 #webform-component-erreichbar-zwischen select, form#webform-client-form-1360 #webform-component-und select {
	float: right;
	width: 45%;
}

form#webform-client-form-55 #webform-component-ihre-daten--anrede-kontakt, #webform-client-form-1360 #webform-component-anrede-label, #webform-client-form-1360 #webform-component-und label {
    display: none;
}

#webform-client-form-1360 #webform-component-erreichbar-zwischen-label, #webform-client-form-1360 #webform-component-und-label {
	display: none;
}

@media screen and (max-width: 450px) {
	form#webform-client-form-55 #webform-component-ihre-fremdsprachen .fieldset-wrapper > div > div > div,
	form#webform-client-form-55 #edit-submitted-kunde-ich-bitte-um-ein-unverbindliches-angebot-fur-ihre-dienstleistung > div {
		width: 50%;
		margin-bottom: 15px;
	}

	form#webform-client-form-55 #webform-component-fuhrerschein-vorhanden, form#webform-client-form-55 #webform-component-eigener-pkw-vorhanden {
		width: 100%;
	}
}
/* ENDE KONTAKTFORMULAR */

/* ENDE RÜCKRUFFORMULAR */

form#webform-client-form-1360 #webform-component-erreichbar-zwischen, form#webform-client-form-1360 #webform-component-und {
	position: relative;
	float: left;
	width: 58%;
}

form#webform-client-form-1360 #webform-component-und {
	clear: both;
}

form#webform-client-form-1360 #webform-component-erreichbar-zwischen select,
form#webform-client-form-1360 #webform-component-und select {
	float: right;
	width: 50%;
}

form#webform-client-form-1360 #webform-component-erreichbar-zwischen-minute, form#webform-client-form-1360 #webform-component-und-minute {
	position: relative;
	width: 29%;
	float: left;
}

form#webform-client-form-1360 #webform-component--1, form#webform-client-form-1360 #webform-component--2 {
	width: 4%;
	float: left;
	text-align: center;
}

form#webform-client-form-1360 #webform-component-uhr-1, form#webform-client-form-1360 #webform-component-uhr-2 {
	width: 9%;
	float: left;
	text-align: center;
}

form#webform-client-form-1360 #webform-component-erreichbar-zwischen-minute select, form#webform-client-form-1360 #webform-component-und-minute select {
    margin-top: 0px;
}

form#webform-client-form-1360 #webform-component-uhr-1 p, form#webform-client-form-1360 #webform-component--1 p, form#webform-client-form-1360 #webform-component-uhr-2 p, form#webform-client-form-1360 #webform-component--2 p {
	padding: 5px 0 0px !important;
}


form#webform-client-form-1360 webform-component-und-label {
	text-align: center;
}

@media screen and (max-width: 520px) {
	form#webform-client-form-1360 #webform-component-erreichbar-zwischen select, form#webform-client-form-1360 #webform-component-und select {
		width: 49%;
	}
	form#webform-client-form-1360 #webform-component-erreichbar-zwischen, form#webform-client-form-1360 #webform-component-und {
		width: 58%;
	}

	form#webform-client-form-1360 #webform-component-erreichbar-zwischen-minute, form#webform-client-form-1360 #webform-component-und-minute {
		width: 25%;
	}
}

@media screen and (max-width: 510px) {
	form#webform-client-form-1360 #webform-component-erreichbar-zwischen select, form#webform-client-form-1360 #webform-component-und select {
		width: 39%;
	}
	form#webform-client-form-1360 #webform-component-erreichbar-zwischen, form#webform-client-form-1360 #webform-component-und {
		width: 58%;
	}

	form#webform-client-form-1360 #webform-component-erreichbar-zwischen-minute, form#webform-client-form-1360 #webform-component-und-minute {
		width: 25%;
	}

}

@media screen and (max-width: 440px) {

	form#webform-client-form-1360 #webform-component-erreichbar-zwischen-minute, form#webform-client-form-1360 #webform-component-und-minute {
		width: 42%;
	}

	form#webform-client-form-1360 #webform-component-erreichbar-zwischen, form#webform-client-form-1360 #webform-component-und {
		width: 45%;
	}

	#webform-client-form-1360 #webform-component-erreichbar-zwischen label, form#webform-client-form-1360 #webform-component-und label {
		width: 100% !important;
	}

	form#webform-client-form-1360 #webform-component-erreichbar-zwischen select, form#webform-client-form-1360 #webform-component-und select {
		width: 100%;
	}

	form#webform-client-form-1360 #webform-component-uhr-1 p, form#webform-client-form-1360 #webform-component--1 p, form#webform-client-form-1360 #webform-component-uhr-2 p, form#webform-client-form-1360 #webform-component--2 p {
		padding: 32px 0 0px !important;
	}

	form#webform-client-form-1360 #webform-component-erreichbar-zwischen-minute select, form#webform-client-form-1360 #webform-component-und-minute select {
    	margin-top: 27px;
	}

}

@media screen and (max-width: 340px) {
	form#webform-client-form-1360 #webform-component-erreichbar-zwischen, form#webform-client-form-1360 #webform-component-und {
		width: 48%;
	}

	form#webform-client-form-1360 #webform-component-erreichbar-zwischen select, form#webform-client-form-1360 #webform-component-und select {
    width: 85%;
    float: left;
}

	form#webform-client-form-1360 #webform-component--1, form#webform-client-form-1360 #webform-component--2 {
		margin-left: -16px;
	}

	form#webform-client-form-1360 #webform-component-uhr-1, form#webform-client-form-1360 #webform-component-uhr-2 {
		width: 12%;
	}

	form#webform-client-form-1360 #webform-component-erreichbar-zwischen-minute, form#webform-client-form-1360 #webform-component-und-minute {
		width: 40%;
	}
}

/* RÜCKRUFFORMULAR */


.intro-logos {
	text-transform: none;
	text-align: center;
	line-height: normal;
	margin-top: 40px;
}

.intro-logos img {
	margin-bottom: 20px;
	height: 50px;
	width: auto;
}

.intro-logos p {
    text-align: left;
}

.headline-paragraph {
	color: #D72D2B;
	display: block;
	font-family: 'AkkoPro', arial, sans-serif;
	font-size: 15px;
	padding-bottom: 15px;
	text-transform: uppercase;
	top: 2px;
    position: relative;
    left: -4px;
}
.red.subtitle {
	font-family: 'AkkoPro', arial, sans-serif !important;
    font-size: 13px;
    letter-spacing: -1px;
}
.unserangebot-kd, .unserangebot-be {
	margin-bottom: 15px;
	padding: 0;
	display: inline-block;
}

#block-system-main .col-md-12 + .col-md-4 {
    padding-left: 0;
}

#block-system-main .col-md-6:nth-child(2n) {
    padding-right: 0;
}


/*#block-system-main .col-md-6:last-of-type,*/
/*#block-system-main .col-md-4:last-of-type {*/
    /*padding-right: 0;*/
/*}*/

#block-system-main .col-md-12 {
	padding: 0;
}

/*
.unserangebot-be .col-md-4:first-of-type {
    padding-left: 0;
}

.unserangebot-be .col-md-4:last-of-type {
    padding-right: 0;
}
*/

/*
.field-name-body .field-item .col-md-4:nth-child(4) {
    padding-right: 0;
}

.field-name-body .field-item .col-md-4:nth-child(2) {
    padding-left: 0;
}*/

.gmap-popup .views-field-title span.field-content {
    display: none;
}

@media screen and (max-width: 767px) {

	.col-xs-12 {
		margin-bottom: 15px;
	}
	.navbar-nav{
  	margin: 0 !important;
	}
}


@media screen and (max-width: 991px) {

	.result-row {border-bottom: 1px solid #eee;padding: 10px 0;}

  .field-name-field-spalte-3 h2 {margin-top: 55px;padding-bottom: 5px;}
  /*body.page-node-57 .job-results:nth-child(odd), .view-interne-stellenangebote .job-results:nth-child(odd),*/
  body.page-node-57 .job-results:nth-child(even), .view-interne-stellenangebote .job-results:nth-child(even){
	  background-color: transparent !important;
	  border: none !important;}
}

@media screen and (max-width: 580px) {
  .result-btn{
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 320px) {
	.front .headline .corner {right: -300px;}
}

/* German Personnel */
.cs-result__title.jobad-title{
  font-size: 14px;
  padding: 0;
}

.cs-result__favorite{
  margin-top: 0;
}

.cs-widget__container{
  margin: 0 !important;
  max-width: 100% !important;
}

.cs-widget-results{
  padding: 0 !important;
}

.cs-result .cs-result__link{
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

.cs-result__link{
  padding-bottom: 0 !important;
}

.cs-widget-simple__button.cs-button.cs-button--search{
  text-align: center;
}

li.cs-result{
  list-style-type: none;
  margin-left: 0;
}

li.cs-result__link{
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

div.cs-result__link {
  padding-right: 15px !important;
}

.page-node-57 {
  .title-box {
    background-image: url("../img/images/zkProfessionals_airbus_black_negativ_rz.png");
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center 70px;
  }
}

.page-node-57 .row-wrapper > .content-row{
  padding: 0;
}

.cs-widget-simple__button.cs-button.cs-button--search{
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.cs-select--basic{
  height: auto;
}
