@media print {

	.main-nav {
		padding: 0;

		.logo {
			display: none;
		}
		.print-contact {
			display: block !important;

			img {
				padding: 0 20pt 0 0;
		    	width: 70pt;
		    	float: left;
			}
			p {
				font-family: Arial, Helvetica, sans-serif;
			   	font-size: 5pt;
			   	line-height: 8pt;
			   	letter-spacing: 0.5pt;
			   	padding: 0;
			}
		}
		.navbar {
			display: none;
		}
	}
	.jobdetail-page {
		.main-slider {
			display: none;
		}
		.content-row {
			.job-detail {
				.inner-content {
					padding: 0;

					h3 {
						font-size: 9pt;
						padding: 0;
					}
					h4 {
						font-size: 7pt;
						padding: 5pt 0 0 0;
					}
					p {
					   font-family: Arial, Helvetica, sans-serif;
					   font-size: 5pt;
					   line-height: 8pt;
					   letter-spacing: 0.5pt;
					   padding: 0;
		  			}
		  			ul {
		  				line-height: 10pt;

		  				li {
		  					font-size: 5pt;
		  					padding: 0;
		  				}
		  			}
				}
			}
		}
		.job-infos {
			.inner-content {
				padding: 0 !important;

				table {
					tr {
						td {
							font-size: 5pt;
						}
					}
				}
			}
			.result-btn {
				display: none;
			}
		}
		.single-box {
			display: none;
		}
	}
	footer {
		.footer {
			display: none;
		}
	}
}
