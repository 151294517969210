@font-face {
  font-family: 'PT_Sans-Regular';
  src: url('../fonts/PT_Sans-Web-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PT_Sans-Italic';
  src: url('../fonts/PT_Sans-Web-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'PT_Sans-Bold';
  src: url('../fonts/PT_Sans-Web-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'fontage';
  src: url('../fonts/Frontage-Regular.otf') format('opentype');
}

/* variables */

$red: #D72D2B;
$dark-grey: #3C3C3B;
$light-grey: #ECECEC;
$green: #006567;

$main-font-size: 14px;
$main-font: 'PT_Sans-Regular', arial, sans-serif;
$italic-font: 'PT_Sans-Italic', arial, sans-serif;
$bold-font: 'PT_Sans-Bold', arial, sans-serif;
$heading-font: 'fontage', arial, sans-serif;

///* general *///
html {
  color: $dark-grey;
  font-size: $main-font-size;
  font-family: $main-font;

  body {
    background: $light-grey;
  }

  /* text/links */
  a {
    color: $dark-grey;
    display: block;

    &:hover {
      color: $red;
      text-decoration: none;

      .icon {
        fill: #fff;
      }
    }
  }

  h1 {
    font-family: $italic-font;
    font-size: 32px;
    padding: 20px 0;
    letter-spacing: 6px;
  }

  h2 {
    font-size: 28px;
    padding-bottom: 15px;
  }

  h3 {
    font-family: $heading-font;
    font-size: 22px;
    padding-bottom: 15px;
  }

  h4 {
    font-family: $bold-font;
    font-size: 14px;
  }

  .red {
    color: $red;
  }

  .grey {
    color: $dark-grey;
  }

  .white {
    color: #fff;
  }

  /* form */
  .newsletter-form,
  .filter-form {
    .form-group {
      label {
        width: 100%;
        padding: 10px 0;
        color: #fff;
        text-transform: uppercase;
      }

      select,
      input {
        width: 100%;
        height: 30px;
        text-transform: uppercase;
      }

      .select-arrow {
        width: 30px;
        height: 30px;
        position: absolute;
        color: #fff;
        background: $dark-grey;
        right: 25px;
        top: initial;
        text-align: center;
        cursor: pointer;

        &:before {
          top: 10px;
          position: relative;
        }
      }

      &.email {
        padding-left: 0;
      }

      &.plz {
        padding-right: 0;
      }
    }

  }

  .job-group-form {
    padding: 20px 0;

    .form-group {
      @extend %clearfix;

      .job-group {
        padding: 10px 0;
        @extend %clearfix;

        input {
          visibility: hidden;
        }

        label {
          cursor: pointer;
          position: absolute;
          width: 30px;
          height: 30px;
          top: 0px;
          background: url(../../img/icons/ZK_Icon_Checkbox.svg);
          background-position: -11px -11px;
          background-size: 40px;
          padding-left: 35px;
        }

        input[type=checkbox]:checked + label {
          cursor: pointer;
          position: absolute;
          width: 30px;
          height: 30px;
          top: 0px;
          background: url(../../img/icons/ZK_Icon_CheckboxOn.svg);
          background-position: -11px -11px;
          background-size: 40px;
          padding-left: 35px;
        }
      }
    }
  }

  /* list elements */

  ul {
    line-height: 35px;

    li {
      list-style-type: square;
      margin-left: 18px;
    }
  }

  .content-list {
    padding: 0 0 15px 0;

    .list-type {
      width: 40px;
      height: 40px;
      background: $dark-grey;
      color: #fff;
      float: left;
      margin: 16px 10px 0 0;

      span {
        font-size: 25px;
        position: absolute;
        left: 13px;
        top: 7px;
      }
    }

    p {
      display: table;
      padding-bottom: 15px;
    }
  }

  /* tables */
  table {
    tr {
      td {
        padding: 10px 10px 0 0;
      }
    }
  }

  /* buttons */
  .red-btn {
    height: 32px;
    min-width: 142px;
    border: none;
    background: $red;
    color: #fff;
    letter-spacing: 3px;
    position: relative;

    &:hover {
      @extend .bold;
    }
  }

  .xing-btn {
    @extend .red-btn;
    background: $green;

    a {
      color: #fff;
    }
  }

  .facebook-btn {
    @extend .red-btn;
    background: #3B5998;

    a {
      color: #fff;
    }
  }

  .google-btn {
    @extend .red-btn;
    background: #dd4b39;

    a {
      color: #fff;
    }
  }

  /* icons */
  .icon {
    width: 70px;
    height: 70px;
    fill: $dark-grey;
  }

  .social-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    margin: 5px;
  }


  /* structure */

  header {
    margin: 20px 0;

    .logo {

      img {
        width: 100%;
        height: auto;
      }
    }

    .print-contact {
      display: none;
    }

    .main-nav {
      padding: 20px;
      @extend %clearfix;

      .navbar {
        margin-bottom: 0;

        .navbar-nav {
          float: right;
          padding-top: 33px;

          li {
            padding: 0 20px;
            margin-left: 0 !important;

            &:hover {
              a {
                background: #fff;
              }

              .dropdown-menu {
                display: block;
              }
            }
          }

          .dropdown {

            &:hover {
              .dropdown-menu {
                display: block;
              }
            }

            .dropdown-menu {
              border: none;
              background: rgba(236, 236, 236, 0.9);

              li {
                list-style-type: none;

                a {
                  background: rgba(236, 236, 236, 0.1);
                  padding: 10px 20px;

                  &:hover {
                    color: $red;
                  }
                }
              }
            }
          }
        }
      }
    }

    .responsive-nav {
      margin: 0;
      padding-top: 10px;

      .navbar {
        margin: 0;

        .collapse {
          position: relative;
        }

        .navbar-toggler {
          float: right;
          border: none;
          font-size: 30px;
          color: $dark-grey;
          background: transparent;
        }

        .navbar-nav {
          .dropdown-menu {
            li {
              list-style-type: none;
            }
          }
        }
      }
    }
  }

  section {
    max-width: 1200px;
    margin: 0 auto;
    height: auto;
    background: #fff;
    @extend %clearfix;

    .slider-section {
      @extend %clear;
    }

    .content-row {
      padding: 0 3px;

      .content-box {
        height: auto;
        border: 3px solid #fff;
        @extend %clear;

        .inner-content {
          padding: 30px;
          @extend %clearfix;
        }
      }

      .single-box {
        height: 200px;
        border: 3px solid #fff;
        text-align: center;
        @extend %clear;

        .inner-content {
          display: block;
          @extend %center;
        }
      }

      .double-box,
      .trible-box {
        @extend .single-box;
      }
    }
  }

  wrapper,
  header,
  footer {
    @extend section;
  }
}

/* extend classes */
%clearfix {
  *zoom: 1;

  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

%clear {
  padding: 0;
  margin: 0;
}

%center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bold {
  font-weight: bold;
}

.left-border-control {
  border-left: 0 !important;
}

.right-border-control {
  border-right: 0 !important;
}

/* boxes */
.red-box {
  background: $red;
  color: #fff;

  &.hover:hover {
    background: $dark-grey;

    a,
    .icon {
      color: #fff;
      fill: #fff;
    }
  }

  a,
  .icon {
    color: #fff;
    fill: #fff;
  }
}

.dark-grey-box {
  background: $dark-grey;
  color: #fff;

  &.hover:hover {
    background: $light-grey;

    a,
    .icon {
      color: $dark-grey;
      fill: $dark-grey;
    }
  }

  a,
  .icon {
    color: #fff;
    fill: #fff;
  }
}

.light-grey-box {
  background: $light-grey;
  color: $dark-grey;

  &.hover:hover {
    background: $red;

    a,
    .icon {
      color: #fff;
      fill: #fff;
    }
  }

  a,
  .icon {
    color: $dark-grey;
    fill: $dark-grey;
  }
}

/* intro-page */

.intro-page {
  padding-bottom: 70px;

  .intro-header {
    background: #fff;
    text-align: center;
    margin: 25px 0;
  }

  wrapper.intro-page {
    padding-bottom: 70px;
  }

  .intro-slider {
    background-size: cover;

    .slider-content {
      background: rgba(255, 255, 255, 0.8);
      width: 600px;
      height: 250px;
      position: absolute;
      left: 0;
      bottom: 10%;

      .inner-content {
        padding: 8% 5%;

      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .intro-footer {

    .intro-logos {
      text-align: center;
      padding: 40px 25px;

      img {
        width: 50%;
      }

      #personal,
      #planwerk {
        margin: 2% 0;
      }
    }

    .next-arrow {
      text-align: center;
      font-size: 20px;
      cursor: pointer;
      padding-bottom: 15px;

      span {
        font-size: 30px;
        color: $red;
      }
    }
  }
}

/* slider */
.main-slider {
  background-size: cover !important;
  min-height: 390px;
  @extend %clear;
  @extend %clearfix;

  .slider-content {
    background: rgba(0, 0, 0, 0.7);
    float: right;
    height: 100%;
    padding: 25px;
  }
}

.responsive-start-page {
  .main-slider {
    background-image: url(../../img/images/Berufsgruppen/intro.jpg);
    background-size: cover;
    border-bottom: 3px solid #fff;
    height: 355px;
    @extend %clear;
  }

  .responsive-slider-content {
    background: rgba(0, 0, 0, 0.7);
    padding: 25px;
    border: 3px solid #FFF;
    min-height: 400px;
  }
}

///* content *///

/* info-boxes*/
.info-white {
  color: #fff;
}

.info-link {
  color: #fff;
  margin-top: 30px;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

/* jop-ticker */
.ticker-header {
  width: 100%;
  height: 50px;
  background: $light-grey;

  h4 {
    @extend %center;
  }
}

.ticker-content {
  width: 100%;
  height: 150px;
  background: #fff;
  overflow: auto;

  ul {
    text-align: left;
    padding: 10px 25px;

    li {
      line-height: 25px;
      list-style: none;

      &:before {
        content: "-";
        padding-right: 8px;
        float: left;
        @extend .bold;
      }
    }
  }
}

/* kunden-page */
.kunden-page {
  .content-row {
    .main-slider {
      border: 3px solid #fff;

      .slider-content {
        background: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 250px;
        position: absolute;
        left: 0;
        bottom: 10%;
      }
    }
  }
}

/* berufsgruppen-page */
.prof-groups {
  @extend %clearfix;
  padding: 30px 0;

  .group-item {
    height: 70px;
    padding: 0;

    &:hover {
      background: $dark-grey;
      fill: #fff;

      .item {
        color: #fff;
      }
    }

    a {
      height: 70px;
      @extend %clearfix;
    }

    .icon {
      float: left;
    }

    .item {
      font-family: $heading-font;
      font-size: 15px;
      @extend %center;
    }
  }
}

/* bewerber-page */
.bewerber-page {

  .main-slider {
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
  }

  .inner-content {
    h4 {
      padding-bottom: 20px;
    }
  }

  .evaluate-list {
    line-height: 35px;

    li {
      list-style-type: square;
      margin-left: 18px;
    }
  }
}

/* jobboerse-page */
.jobboerse-page {

  .content-row {

    .inner-content {

      .job-header {
        @extend %clear;

        .job-intro {
          @extend %clear;

          .result-count {
            color: $red;
          }
        }
      }

      .filter-results {

        h4 {
          padding-bottom: 15px;
        }

        ul {
          line-height: 25px;

          li {
            list-style-type: none;
            @extend %clear;

            .remove {
              float: left;
              padding-right: 5px;
              @extend .bold;
            }
          }
        }
      }
    }
  }

  .job-results {
    border: 3px solid #fff;
    padding: 5px 15px 10px 15px;
    @extend %clearfix;

    &:nth-child(odd) {
      background-color: $light-grey;
    }

    .result-row {
      tr {
        td {
          &.description {
            @extend .bold;
          }
        }
      }

      .result-btn {
        button {
          margin: 20px 0;
          min-width: 100px;

          a {
            color: #fff;
          }
        }
      }
    }
  }
}

/* jobdetail-page */
.jobdetail-page {
  .job-detail {
    .inner-content {
      h4 {
        padding: 25px 0 10px 0;
      }
    }
  }

  .job-infos {
    .result-btn {
      padding: 20px 0 0 0;

      button {
        margin: 0 6px 6px 0;
        text-align: right;

        &.red-btn {
          line-height: 30px;

          span {
            position: absolute;
            font-size: 20px;
            left: 0;
            margin: 5px 8px;
          }
        }

        &.xing-btn {
          height: 33px;
          top: -1px;
        }

        &.facebook-btn {
          margin-bottom: 0;

          .social-icon {
            margin: 5px 3px;
          }
        }

        &.google-btn {
          margin-bottom: 0;

          .social-icon {
            height: 30px;
            width: 30px;
            margin: 0 5px;
          }
        }
      }
    }
  }
}

/* footer */
footer {
  .footer {
    #copyright,
    #impressum,
    span {
      float: right;
      padding: 18px 5px;
    }
  }
}

#admin-menu {
  z-index: 99999;
}

.field-name-body p.col-md-6:first-child {
  padding-right: 30px !important;
}

strong {
  font-weight: bold;
  font-family: 'PT Sans', sans-serif;
}

.iconbig {
  font-size: 44px;
}

body.page-node-57 .main-slider .title-box .region-header-overlay h2 {
  padding-bottom: 20px;
}

.hover {
  cursor: pointer;
}

.hover.print:hover {
  background: #3C3C3B;
  color: #fff;
}

img {
  height: auto;
}

.white-box img {
  width: 100%;
}

.view-referenzen ul {
  padding: 0;
  margin: 0;
}

.view-referenzen ul li {
  float: left;
  list-style-type: none;
  position: relative;
  background: #fff;
  border: 3px solid #eee;
  width: 25%;
  margin: 0;
}

.standorte .views-field-field-e-mailadresse a:hover {
  color: #d72d2b !important;
}

.view-referenzen .single-box {
  border: none;
  height: auto !important;
  background: #fff;
  display: block;
}

.form-item-jobtitel label .subline {
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 30px;
}

body.node-type-interner-job h1.title {
  color: #d72d2b;
  margin: 0 0 40px;
  line-height: 1;
}

.form-item-head {
  font-size: 30px !important;
  line-height: 40px;
  margin-bottom: 50px;
  text-transform: uppercase;
}


#crusoe-searchblock-form label.compact-form-label,
#crusoe-searchblock-form input.compact-form-field {
  font-size: 13px;
  height: 30px;
  letter-spacing: 1px;
  line-height: 30px;
  margin: 0;
  text-transform: uppercase;
}

#crusoe-searchblock-form label.compact-form-label {
  padding-left: 5px;
}

/*
body:not(.front) footer {
}*/

body.front footer {
  position: fixed;
  bottom: 0;
  z-index: 498;
  max-width: 100%;
}

#edit-field-berufsgruppe-tid-1 {
  cursor: pointer;
}

.view-referenzen .single-box img {
  -webkit-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
  filter: grayscale(100%);
  filter: gray;
  -moz-opacity: 0.7;
  opacity: 0.7;
  padding: 20px
}

.view-referenzen .single-box img:Hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -webkit-filter: grayscale(0);
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  cursor: default;
}

.logos {
  max-width: 200px;
}

.logos a img {
  float: left;
  max-width: 40%;
  margin: 5px;
}

body.page-node-1268 .content-row .content-box,
body.page-node-1268 .content-row .content-box .inner-content,
body.page-node-57 .content-row .content-box,
body.page-node-57 .content-row .content-box .inner-content {
  border: none;
  border-top: 2px solid white;
  padding: 0;
}

body.page-node-62 .content-row .content-box {
  border-right: 3px solid #fff !important;
}

body.page-node-2931 .content-row .content-box,
body.page-node-2936 .content-row .content-box,
body.page-node-2930 .content-row .content-box,
body.page-node-2932 .content-row .content-box,
body.page-node-2935 .content-row .content-box,
body.page-node-2933 .content-row .content-box,
body.page-node-2977 .content-row .content-box,
body.page-node-2975 .content-row .content-box,
body.page-node-2979 .content-row .content-box,
body.page-node-2973 .content-row .content-box,
body.page-node-2978 .content-row .content-box,
body.page-node-2976 .content-row .content-box {
  border-right: 3px solid #fff !important;
}

body.page-node-2931 .content-row .single-box,
body.page-node-2936 .content-row .single-box,
body.page-node-2930 .content-row .single-box,
body.page-node-2932 .content-row .single-box,
body.page-node-2935 .content-row .single-box,
body.page-node-2933 .content-row .single-box,
body.page-node-2977 .content-row .single-box,
body.page-node-2975 .content-row .single-box,
body.page-node-2973 .content-row .single-box,
body.page-node-2979 .content-row .single-box,
body.page-node-2978 .content-row .single-box,
body.page-node-2976 .content-row .single-box {
  border-left: 3px solid #fff !important;
  border-right: 0 !important;
}

body.page-node-2931 .content-row .content-box.dark-grey-box,
body.page-node-2936 .content-row .content-box.dark-grey-box,
body.page-node-2930 .content-row .content-box.dark-grey-box,
body.page-node-2932 .content-row .content-box.dark-grey-box,
body.page-node-2935 .content-row .content-box.dark-grey-box,
body.page-node-2933 .content-row .content-box.dark-grey-box,
body.page-node-2976 .content-row .content-box.dark-grey-box,
body.page-node-2977 .content-row .content-box.dark-grey-box,
body.page-node-2973 .content-row .content-box.dark-grey-box,
body.page-node-2978 .content-row .content-box.dark-grey-box,
body.page-node-2979 .content-row .content-box.dark-grey-box,
body.page-node-2975 .content-row .content-box.dark-grey-box {
  border-left: 0 !important;
  padding: 0 !important;
}

td.published-date {
  width: 100px;
}

#views-exposed-form-jobb-rse-block-1 .description {
  display: none;
}

#views-exposed-form-jobb-rse-block-1 .views-widget-filter-title,
#views-exposed-form-jobb-rse-block-1 .views-widget-filter-field_standort_value,
#views-exposed-form-jobb-rse-block-1 .views-widget-filter-field_berufsgruppe_tid_1 {
  width: 100%;
}

fieldset#webform-component-daten {
  background: #22588f none repeat scroll 0 0;
  left: 0;
  margin-left: 15px;
  max-width: none;
  min-width: auto;
  padding: 20px;
  position: absolute;
  top: 0;
  width: 33.3%;
}

fieldset#webform-component-daten label {
  color: white;
}

fieldset#webform-component-daten input.form-text,
fieldset#webform-component-daten select.form-select {
  height: 30px;
  text-transform: uppercase;
  width: 100%;
}

#webform-component-daten #webform-component-daten--e-mailadresse,
#webform-component-daten #webform-component-daten--postleitzahlbereich-nl {
  float: left;
  width: 48%;
}

#webform-component-daten #webform-component-daten--e-mailadresse {
  margin-right: 4%;
}

#webform-component-daten > .fieldset-wrapper > div {
  margin-bottom: 20px;
  margin-top: 0;
}

#webform-component-daten .form-required {
  color: white;
}

#edit-submitted-berufsgruppen > .form-item {
  float: left;
  margin: 0;
  min-height: 1px;
  padding: 10px 0;
  position: relative;
  width: 25%;
}

#edit-submitted-berufsgruppen > .form-item label {
  padding: 0 5px 0 35px !important;
  word-break: break-word;
}

#edit-submitted-berufsgruppen > .form-item input {
  visibility: hidden;
}

body #edit-submitted-berufsgruppen label,
body #edit-submitted-berufsgruppen input[type="checkbox"]:checked + label {
  width: 100%;
  background-repeat: no-repeat;
}

#webform-client-form-62 #edit-actions {
  clear: both;
}

#webform-client-form-62 #edit-actions input {
  background: #d72d2b none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 32px;
  letter-spacing: 3px;
  min-width: 142px;
  position: relative;
}

.job-infos .result-btn {
  padding: 20px 0 0;
}

.job-infos .result-btn button {
  margin: 0 6px 6px 0;
  text-align: right;
}

.job-infos .result-btn button.red-btn, .job-infos .result-btn html button.xing-btn, html .job-infos .result-btn button.xing-btn, .job-infos .result-btn html button.facebook-btn, html .job-infos .result-btn button.facebook-btn, .job-infos .result-btn html button.google-btn, html .job-infos .result-btn button.google-btn {
  line-height: 30px;
}

.job-infos .result-btn button.red-btn span, .job-infos .result-btn html button.xing-btn span, html .job-infos .result-btn button.xing-btn span, .job-infos .result-btn html button.facebook-btn span, html .job-infos .result-btn button.facebook-btn span, .job-infos .result-btn html button.google-btn span, html .job-infos .result-btn button.google-btn span {
  position: absolute;
  font-size: 20px;
  left: 0;
  margin: 5px 8px;
}

.job-infos .result-btn button.xing-btn {
  height: 33px;
  top: -1px;
}

.job-infos .result-btn button.facebook-btn .social-icon {
  margin: 5px 3px;
}

.job-infos .result-btn button.google-btn {
  margin-bottom: 0;
}

.job-infos .result-btn button.google-btn .social-icon {
  height: 30px;
  width: 30px;
  margin: 0 5px;
}

body.node-type-interner-job h3.title {
  padding-bottom: 15px !important;
}

div.node-interner-job div.field-label {
  padding: 25px 0 10px;
}

/*div.node-interner-job .field-name-field-einleitungstext {*/
/*font-size: 15px;*/
/*letter-spacing: 1px;*/
/*line-height: 20px;*/
/*padding: 10px 0 !important;*/
/*text-transform: none;*/
/*}*/
.logo-row div {
  text-align: center;
  height: 70px;
}

.logo-row div a {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.logo-row div a img {
  width: 50%;
}

/*.navbar-nav>li {*/
/*float: none;*/
/*display: inline-block;*/
/*}*/
/*.navbar .region-headmenu {*/
/*display: table;*/
/*margin: 0  0  0 auto;*/
/*}*/
.navbar .region-headmenu h2 {
  padding: 0;
}

/*.responsive-nav {*/
/*padding-bottom: 10px;*/
/*}*/
.responsive-nav .logo-row {
  position: relative;
  z-index: 9999;
}

.responsive-nav .navbar {
  text-align: center;
  margin-bottom: 0;
}

/*html header .responsive-nav {*/
/*padding-left: 3px;*/
/*padding-right: 3px;*/
/*}*/
.responsive-nav .navbar div,
.responsive-nav .navbar .dropdown-menu {
  width: 100%;
}

.responsive-nav .navbar .navbar-toggler {
  margin: 18px 0;
}

.responsive-nav .navbar .dropdown .dropdown-menu {
  position: relative;
  border: none;
  box-shadow: none;
  background: #eee;
  margin-bottom: 20px;
  padding: 0 0 10px 0;
  min-width: 189px;
}

.responsive-nav .navbar .dropdown .dropdown-menu li {
  text-align: center;
  margin: 0;
  padding: 5px 0;
}

.main-nav .navbar-nav > li {
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}

.main-nav .navbar-nav > li:hover {
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}

.main-slider .region-header-overlay .slider-content {
  padding-top: 0;
}

body #webform-component-daten--bleiben-sie-auf-dem-laufenden h2,
body .slider-content h2 {
  font-family: inherit;
}

body .geofield-proximity-field-wrapper .form-item,
body .geofield-proximity-field-wrapper .geofield-proximity-origin-from {
  margin-right: 0;
}

body .ajax-progress, .ajax-progress.ajax-progress-throbber {
  z-index: 99999 ! important;
}

#edit-field-geofield-distance-wrapper .form-item-field-geofield-distance-unit {
  display: none;
}

#edit-field-geofield-distance-wrapper .form-item-field-geofield-distance-distance {
  width: 16%;
  margin: 0;
}

#edit-field-geofield-distance-wrapper .geofield-proximity-origin-from {
  color: #ffffff;
  margin: 0;
  padding-top: 0;
  text-align: center;
  width: 50%;
}

.job-results table tr td {
  padding-top: 0;
}

/*.region-header-overlay .views-exposed-form .views-exposed-widget .form-submit {*/
/*height: 30px !important;*/
/*letter-spacing: 0 !important;*/
/*line-height: 14px;*/
/*margin-top: 18px;*/
/*}*/


.geofield-proximity-field-wrapper .form-item, .geofield-proximity-field-wrapper .geofield-proximity-origin-from {
  float: left;
}

body.front .cs-widget-simple {
  background: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
}

body.front .cs-widget-simple__location-wrapper,
body.front .cs-widget-simple input {
  max-width: 280px;
  border: none !important;
}

body.front .cs-widget-simple select {
  border-color: #fff !important;
}

body.front .cs-widget-simple input,
body.front .cs-widget-simple button {
  margin-left: 0;
  float: left;
}

body.front .cs-widget-simple__clear-wrapper,
body.front .cs-widget-simple__inputs-wrapper {
  justify-content: normal;
  text-align: left;
  color: #fff;

  .cs-widget-simple__clear {
    .cs-icon {
      display: none;
    }
  }
}

body.front .cs-widget-simple__radius-wrapper {
  background: #fff;
}

.slider-content .inner-content p {
  color: #fff;
}

/* import mobile and print */
@import "print";
@import "main";
@import "ie";
@import "mobile";
