$red: #D72D2B;
$dark-grey: #3C3C3B;
$light-grey: #ECECEC;
$green: #006567;

@media screen and (min-width: 1025px) {
  .responsive-nav {
    display: none;
  }
  .start-page {
    .main-slider {
      min-height: 390px;
    }
  }
  .responsive-start-page {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .responsive-nav {
    display: none;
  }
  .start-page {
    display: block;

    .main-slider {
      background-position: center center !important;

      .frontpage-zklogo {
        width: 30%;
        bottom: 140px;

        img {
          width: 100%;
        }
      }
    }

    .region-searchblock {
      left: 5%;
      bottom: 20px;

      #widget {
        .cs-widget {
          .cs-widget-simple__inputs-wrapper {
            display: flex;
            flex-direction: column;
            margin: 0;

            .cs-widget__field-wrapper, .cs-widget-simple__location-wrapper {
              height: 40px;
              margin: 0 0 20px 0;

              .cs-widget-simple__input {
                width: 100%;
                border: none !important;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0 0 0 40px;
                font-size: 20px;
                margin: 0;
              }

              .cs-icon {
                width: 20px;
                top: 0;
                left: 9px;
              }

              &.cs-widget-simple__radius-wrapper {
                .cs-select--basic {
                  padding: 0 0 0 20px;
                  font-size: 18px;
                  line-height: normal;
                  border: 0 solid transparent;
                }

                .cs-icon {
                  width: 10px;
                }
              }
            }

            .cs-widget-simple__button {
              height: 50px;
              margin: 0;
              display: flex;
              align-items: center;
              line-height: 0;
              font-size: 20px;
              padding-left: 41px;

              .cs-icon {
                right: 24px;
                width: 24px;
              }
            }
          }
        }
      }
    }
  }
  .main-nav {
    .navbar {
      .navbar-nav {
        padding-top: 6px !important;

        li {
          padding: 0 !important;
        }
      }
    }
  }
  .intro-page {
    .intro-footer {
      .next-arrow {
        margin-top: 60px;
      }
    }
  }
  .prof-groups {
    .group-item {
      .item {
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: 989px) {
  .left-border-control {
    border-left: 3px solid #fff !important;
  }
  .right-border-control {
    border-right: 3px solid #fff !important;
  }

  /* special pages */
  .jobboerse-page {
    .inner-content {
      .job-header {
        .filter-results {
          padding: 20px 0 0 0;
        }
      }
    }

    .job-results {
      padding: 0;

      &:nth-child(odd) {
        background-color: transparent;
      }

      .result-row {

        &:nth-child(even) {
          background-color: $light-grey;
        }

        .result-btn {
          padding: 5px 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-nav {
    display: none;
  }
  .responsive-nav {
    display: block;
  }

  /* special pages*/
  .bewerber-page {

    .main-slider {
      border-top: 0;
      border-right: 0;
      border-bottom: 3px solid #fff;
    }
  }
}

@media screen and (max-width: 650px) {

  .start-page .main-slider {
    height: 70vw;
  }

  #edit-field-geofield-distance-distance {
    min-width: 33px;
  }
  .main-slider {
    height: 245px;
  }
  .hastitlebox {
    overflow: visible;
  }

  .unternehmen-page {
    top: 0;

    .blau.hastitlebox .title-box {
      height: auto;
      max-width: none;
      bottom: -288px;
      width: 100%;
      min-height: 150px;
      &:before {
        content: none;
      }
    }
  }
  .title-box {
    height: auto;
    max-width: none;
    top: 0px;
    width: 100%;
    min-height: 150px;
    position: relative;
  }
  .title-box h2 {
    padding: 35px 25px 35px;
  }
  .title-box h2 br {
    display: none;
  }
  .title-box:before {
    content: "";
    top: -6.5vw;
    background: #ba774b;
    position: absolute;
    right: 4px;
    width: 120%;
    transform: rotate(6deg);
    height: 22vw;
  }

  .node-standort {
    text-align: center;
  }
  .start-page {
    .main-slider {
      .frontpage-zklogo {
        bottom: 40px;
      }
    }

    .region-searchblock {
      position: relative;
      left: 0 !important;
      top: 20px;
      width: 100%;


      .block-block {
        padding: 0 20px;

        h2 {
          color: #222;
        }

        #widget {
          .cs-widget__container {
            .cs-widget-simple__container {
              .cs-widget-simple__inputs-wrapper {
                .cs-widget-simple__separator {
                  display: none;
                }

                .cs-widget-simple__input-wrapper {
                  .cs-widget-simple__input {
                    width: 100% !important;
                    margin: 0 !important;
                    max-width: 100%;
                    border: 1px solid rgba(0, 0, 0, 0.1) !important;
                  }
                }
              }
            }
          }
        }
      }

    }
  }

  .front .headline {
    height: auto;
    padding: 5px 35px;
  }
  .front .headline:before {
    top: -10px;
    left: -3px;
  }
  #crusoe-searchblock-form .form-item.form-type-textfield.form-item-jobtitel {
    width: 100%;
    margin-bottom: 10px;
  }
  #crusoe-searchblock-form label.compact-form-label, #crusoe-searchblock-form input.compact-form-field {
    font-size: 11px !important;
    color: #000;
  }
  .region-searchblock .form-item-head {
    font-size: 20px !important;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .region-searchblock .form-btn a {
    font-size: 10.5px;
    color: #fff;
  }
  .region-searchblock .form-btn a:hover {
    color: #fff;
  }
  #edit-field-geofield-distance-wrapper .form-item-field-geofield-distance-origin {
    width: 84%;
    margin-left: 0;
  }
  #edit-field-geofield-distance-wrapper .form-item-field-geofield-distance-distance {
    width: 16%;
    padding-right: 20px;
  }
  .region-header-overlay .views-exposed-form .views-exposed-widgets > .views-exposed-widget.views-submit-button {
    padding-right: 0;
    clear: both;
    width: 73% !important;
  }
  .region-header-overlay .views-exposed-form .views-exposed-widgets > .views-exposed-widget {
    padding-right: 0;
    width: 100% !important;
  }
}

@media screen and (max-width: 580px) {
  html {
    h3 {
      font-size: 18px;
    }

    .col-xxs-12 {
      width: 100%;
    }

    header {
      .responsive-nav {
        padding: 10px 0 0 5px;
      }

      .logo {
        padding: 0;

        img {
          width: 75px;
          height: auto;
          margin-left: 5px;
        }
      }
    }

    section {
      .content-row {
        .content-box {
          .inner-content {
            padding: 15px;
          }
        }
      }
    }

    .responsive-start-page,
    .unternehmen-page,
    .kunden-page,
    .bewerber-page,
    .berufsgruppen-page {
      .main-slider {
        display: none;
      }
    }
  }
  /* special pages */
  .intro-page {
    .intro-slider {
      .slider-content {
        width: 100%;
      }
    }

    .intro-footer {
      .intro-logos {
        padding: 40px 0px;

        img {
          width: 85%;
        }
      }
    }
  }

  .jobboerse-page {
    .job-results {
      .result-row {
        .result-btn {
          text-align: center;
        }
      }
    }
  }
  #block-block-55 {
    display: none;
  }

  html h3 {
    font-size: 18px;
  }

  html .col-xxs-12 {
    width: 100%;
  }

  html header .logo {
    padding: 0;
  }

  html header .logo img {
    width: 75px;
    height: auto;
    margin-left: 5px;
  }

  html section .content-row .content-box .inner-content, html wrapper .content-row .content-box .inner-content, html header .content-row .content-box .inner-content, html footer .content-row .content-box .inner-content {
    padding: 15px;
  }

  .intro-page .intro-slider .slider-content {
    width: 100%;
  }

  .intro-page .intro-footer .intro-logos {
    padding: 40px 0;
  }

  .intro-page .intro-footer .intro-logos img {
    width: 85%;
  }

  body.page-node-57 .job-results .result-row .result-btn {
    text-align: center;
  }
}

@media screen and (max-width: 530px) {
  #webform-client-form-1360 #webform-component-erreichbar-zwischen {
    margin-top: 2px !important;
  }
  #webform-client-form-1360 #webform-component-und {
    margin-top: 2px !important;
  }
}

@media screen and (max-width: 374px) {
  html {
    .content-list {
      p {
        display: block;
      }
    }

    footer {
      font-size: 11px !important;
    }

    .prof-groups {
      .group-item {
        .item {
          font-size: 11px;
        }
      }
    }
  }

  /* special page */
  .job-infos {
    .result-btn {
      button {
        &.facebook-btn {
          margin-bottom: 6px !important;
        }
      }
    }
  }
  html .content-list p {
    display: block;
  }

  html footer {
    font-size: 11px !important;
  }

  html .prof-groups .group-item .item {
    font-size: 11px;
  }

  .job-infos .result-btn button.facebook-btn {
    margin-bottom: 6px !important;
  }
}
